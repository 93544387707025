.profileContact {
    &__title {
        //margin: auto 0;
        font-weight: 100;
    }
    &__noMessages {
        font-weight: 100;
    }
    &__list {
        display: flex;
        flex-direction: column;
        row-gap: 1rem;
        padding: 0;
        &__element {
            list-style-type: none;
            cursor: pointer;
            &__name-time {
                display: flex;
                flex-direction: row;
                justify-content: space-between;

                &__name,
                &__time {
                    margin: 0;
                    font-weight: 100;
                    font-size: 18px;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                }
            }
            &__text {
                font-weight: 100;
                margin-top: 0;
                text-overflow: ellipsis;
                overflow: hidden;
                white-space: nowrap;
            }
        }
    }
    &__messages {
        display: flex;
        flex-direction: column;
        &__header {
            display: flex;
            flex-direction: row;
            align-items: center;
            border-bottom: 1px solid;
            padding-bottom: 1rem;
            &__arrow {
                width: 32px;
                height: 32px;
                cursor: pointer;
            }
            &__username {
                font-weight: 100;
                margin: 0;
                font-size: 24px;
            }
        }
        &__conversation {
            flex: 1;
            margin: 1rem 0;
            overflow-x: auto;
        }
        &__footer{
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            padding-top: 1rem;
            border-top: 1px solid;
            &__input{
                background-color: white;
                border-radius: 5px;
            }
            &__send{
                width: 32px;
                height: 32px;
                cursor: pointer;
            }
        }
    }
}


@media (max-width: 424px){
    .profileContact{
        &__messages{
            &__footer{
                &__input{
                    width: 80%;
                }
            }
        }
    }
}

@media (min-width : 425px) and (max-width: 767px){
    .profileContact{
        &__messages{
            &__footer{
                &__input{
                    width: 85%;
                }
            }
        }
    }
}

@media (min-width : 768px) and (max-width: 1023px){
    .profileContact{
        &__list{
            &__element{
                width: 60%;
            }
        }
        &__messages{
            &__footer{
                &__input{
                    width: 85%;
                }
            }
        }
    }
}

@media (min-width: 1024px) and (max-width: 1439px){
    .profileContact{
        &__list{
            &__element{
                width: 45%;
            }
        }
        &__messages{
            &__footer{
                &__input{
                    width: 90%;
                }
            }
        }
    }
}

@media (min-width: 1440px){
    .profileContact{
        &__list{
            &__element{
                width: 40%;
            }
        }
        &__messages{
            &__footer{
                &__input{
                    width: 90%;
                }
            }
        }
    }
}
