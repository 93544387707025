.aboutme{
    &__stack{
        margin: auto;
        &__title{
            font-weight: 100;
        }
        &__list{
            display: flex;
            flex-direction: row;
            justify-content: space-evenly;
            flex-wrap: wrap;
            column-gap: 4rem;
            padding: 0;
            row-gap: 2rem;
        }
        &__element{
            display: flex;
            flex-direction: column;
            align-items: center;
            list-style-type: none;
            width: min-content;
        }
        &__icon{
            width: 64px;
            height: 64px;
            object-fit: contain;
        }
        &__name{
            font-weight: 100;
            margin: 0;
            text-align: center;
        }
    }
}

@media (max-width: 424px){
    .aboutme{
        &__stack{
            width: 90%;
            /*&__icon{
                width: 32px;
                height: 32px;
            }
            &__name{
                margin-top: 2px;
                font-size: 12px;
            }
            &__title{
                font-size: 18px;
            }   */         
        }
    }
}

@media (min-width : 425px) and (max-width: 767px){
    .aboutme{
        &__stack{
            width: 80%;
            &__icon{
                width: 48px;
                height: 48px;
            }
            &__name{
                margin-top: 4px;
                font-size: 14px;
            }
            &__title{
                font-size: 20px;
            }            
        }
    }
}

@media (min-width : 768px) and (max-width: 1023px){
    .aboutme{
        &__stack{
            width: 70%;
            &__icon{
                width: 72px;
                height: 72px;
            }
            &__name{
                margin-top: 6px;
                font-size: 18px;
            }
            &__title{
                font-size: 24px;
            }            
        }
    }
}

@media (min-width : 1024px) and (max-width: 1439px){
    .aboutme{
        &__stack{
            width: 65%;
            &__icon{
                width: 96px;
                height: 96px;
            }
            &__name{
                margin-top: 8px;
                font-size: 20px;
            }
            &__title{
                font-size: 28px;
            }
        }
    }
}

@media (min-width : 1440px){
    .aboutme{
        &__stack{
            width: 60%;
            &__icon{
                width: 128px;
                height: 128px;
            }
            &__name{
                margin-top: 10px;
                font-size: 24px;
            }
            &__title{
                font-size: 32px;
            }
        }
    }
}

.dark{
    .aboutme{
        &__stack{
            padding: 1.5rem;
            border-radius: 5px;
            background-color: rgb(58, 58, 58);
        }
    }
}

.light{
    .aboutme{
        &__stack{
            padding: 1.5rem;
            border-radius: 0px;
            background-color: white;
        }
    }
}