.header{
    padding: 1rem 3rem;
    z-index: 50;
    &__wrapper{
        display: flex;
        justify-content: space-between;
        align-items: center;
        max-width: 1440px;
        margin: auto;
    }
    &__name, &__frontend, &__nav_element{
        margin: 0;
        font-weight: 100;
    }
    &__nav_element{
        display: flex;
        align-items: inherit;
        height: 34px;
        padding: 0;
        cursor: pointer;
        list-style-type: none;

    }
    &__nav_list{
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: 0;
        column-gap: 3rem;
        font-size: x-large;
        margin: 6px 0;
    }
    &__option{
        cursor: pointer;
    }
    &__button{
        background-color: transparent;
    }
    &__menu-btn{
        width: 32px;
        height: 32px;
        cursor: pointer;
    }
    &__nav{
        display: flex;
        align-items: center;
    }
    &__link{
        text-decoration: none;
    }
    &__icon{
        width: 32px;
        height: 32px;
    }
}

.hover_none{
    display: flex;
    align-items: inherit;
    height: 34px;
    padding: 0;
    font-weight: 100;
}

.hover_none:hover{
    cursor: default;
    text-decoration: none;
}

#language_button{
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: transparent;
    box-shadow: none;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
    font-weight: 100;
    font-size: x-large;
    text-transform: none;
    //min-width: 50px;
    padding: 0;
    column-gap: .2rem;
}

#language_button:hover{
    background-color: transparent;
}

.menu-btn_light{
    filter: invert(0);
}
.menu-btn_dark{
    filter: invert(1);
}

@media (max-width: 424px){
    .header{
        padding: 1rem;
        &__name{
            font-size: 16px;
        }
        &__frontend{
            font-size: 13px;
        }
        &__nav_element{
            font-size: 20px;
            justify-content: space-between;
        }
        &__nav_list{
            display: none;
        }
        &__flag{
            width: 48px;
            height: auto;
        }
        &__menu-btn{
            display: initial;
        }
        &__back{
            width: 32px;
            height: 32px;
        }
    }
    .hover_none{
        font-size: 20px;
        justify-content: space-between;
    }
}

@media (min-width : 425px) and (max-width: 767px){
    .header{
        &__name{
            font-size: 18px;
        }
        &__frontend{
            font-size: 15px;
        }
        &__nav_element{
            font-size: 22px;
            justify-content: space-between;
        }
        &__nav_list{
            display: none;
        }
        &__flag{
            width: 48px;
            height: auto;
        }
        &__menu-btn{
            display: initial;
        }
        &__back{
            width: 32px;
            height: 32px;
        }
    }
    .hover_none{
        font-size: 22px;
        justify-content: space-between;
    }
}

@media (min-width : 768px) and (max-width: 1023px){
    .header{
        &__flag{
            width: 32px;
            height: auto;
        }
        &__menu-btn{
            display: none;
        }
        &__nav_list{
            column-gap: 2rem;
        }
        &__back{
            width: 32px;
            height: 32px;
        }
    }
}

@media (min-width: 1024px) and (max-width: 1439px){
    .header{
        &__flag{
            width: 32px;
            height: auto;
        }
        &__menu-btn{
            display: none;
        }
        &__nav_list{
            column-gap: 3rem;
        }
        &__back{
            width: 48px;
            height: 48px;
        }
    } 
}

@media (min-width: 1440px){
    .header{
        &__flag{
            width: 32px;
            height: auto;
        }
        &__menu-btn{
            display: none;
        }
        &__back{
            width: 48px;
            height: 48px;
        }
    }
}

.css-4t3x6l-MuiPaper-root-MuiDrawer-paper{
    .css-1d3z3hw-MuiOutlinedInput-notchedOutline{
        border-width: 0;
    }
    .css-1d3z3hw-MuiOutlinedInput-notchedOutline:focus{
        border-width: 0;
    }
}


#language_button_drawer{
    padding: 7px;
}

.select_sidemenu {
    fieldset{
        border-style: none;
    }
}