.footer{
    padding: 3rem;
    font-weight: 100;
    &__link{
        text-decoration: none;
    }
    &__link:hover{
        text-decoration: underline;
    }
}

@media (max-width: 424px){
    .footer{
        padding: 3rem .5rem;
        word-break: break-all;
    }
}

@media (min-width : 425px) and (max-width: 767px){
    .footer{
        display: flex;
        flex-direction: column;
    }
}

@media (min-width : 768px) and (max-width: 1023px){
    .footer{
        display: flex;
        flex-direction: column;
    }
}

@media (min-width : 1024px) and (max-width: 1439px){
    .footer{
        display: flex;
        justify-content: space-evenly;
        flex-wrap: wrap;
    } 
}

@media (min-width : 1440px){
    .footer{
        display: flex;
        justify-content: space-evenly;
    } 
}