.profile{
    min-height: 150vh;
    padding-top: 3rem;
    margin: auto;
    &__wrapper{
        margin: auto;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        row-gap: 2rem;
    }
    &__welcome{
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        &__welcomeMessage,&__lastlogin{
                font-weight: 100;
                margin: 0;
        }
    }
    &__profile{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        //width: 100%;
        &__contact{
            width: 100%;
        }
    }
}

@media (max-width: 424px) {
    .profile {
        padding-top: 1rem;
        &__wrapper {
            width: 95%;
        }

        &__form, &__invalid {
            width: 100%;
            padding: 1rem 0;
        }
    }
}

@media (min-width : 425px) and (max-width: 767px) {
    .profile {
        padding-top: 1rem;
        &__wrapper {
            width: 90%;
        }

        &__form, &__invalid {
            width: 100%;
            padding: 1rem 0;
        }
    }
}

@media (min-width : 768px) and (max-width: 1023px) {
    .profile {
        &__wrapper {
            width: 90%;
        }
    }
}

@media (min-width: 1024px) and (max-width: 1439px) {
    .profile {
        &__wrapper {
            width: 80%;
        }
    }
}

@media (min-width: 1440px) {
    .profile {
        &__wrapper {
            width: 75%;
        }
    }
}