.conversation{
    display: flex;
    flex-direction: column;
    margin: auto;
    &__header {
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: 1rem;
        &__arrow {
            width: 32px;
            height: 32px;
            cursor: pointer;
        }
        &__username {
            font-weight: 100;
            margin: 0;
            font-size: 24px;
        }
    }
    &__conversation {
        flex: 1;
        padding: 1rem;
        overflow-x: auto;
        &__wrapper{
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            &__container{
                display:flex;
                flex-direction: column;
                align-items: flex-start;
                padding-top: 3rem;
                row-gap: 1rem;
                &__welcome,&__send{
                    font-weight: 100;
                    margin: 0;
                }
                &__send{
                    color: grey;
                }
            }
        }
    }
    &__footer{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        padding: 1rem;
        position: sticky;
        bottom: 0;
        &__input{
            background-color: white;
            border-radius: 5px;
        }
        &__send{
            width: 32px;
            height: 32px;
            //cursor: pointer;
        }
    }
}

#header_wrapper{
    justify-content: flex-start;
}

#chat_section{
    flex-direction: column;
    row-gap: 1rem;
}

@media (max-width: 424px){
    .conversation{
        &__conversation{
            &__wrapper{
                width: 95%;
                margin: auto;
            }
        }
        &__footer{
            &__input{
                width: 80%;
            }
        }
    }
}

@media (min-width : 425px) and (max-width: 767px){
    .conversation{
        &__conversation{
            &__wrapper{
                width: 90%;
                margin: auto;
            }
        }
        &__footer{
            &__input{
                width: 85%;
            }
        }
    }
}

@media (min-width : 768px) and (max-width: 1023px){
    .conversation{
        &__conversation{
            &__wrapper{
                width: 80%;
                margin: auto;
            }
        }
        &__footer{
            &__input{
                width: 85%;
            }
        }
    }
}

@media (min-width: 1024px) and (max-width: 1439px){
    .conversation{
        &__conversation{
            &__wrapper{
                width: 60%;
                margin: auto;
            }
        }
        &__footer{
            &__input{
                width: 90%;
            }
        }
    }
}

@media (min-width: 1440px){
    .conversation{
        &__conversation{
            &__wrapper{
                width: 50%;
                margin: auto;
            }
        }
        &__footer{
            &__input{
                width: 90%;
            }
        }
    }
}