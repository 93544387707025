.projects{
    display: flex;
    flex-direction: column;
    row-gap: 1rem;
    padding-top: 10rem;
    padding: 10rem 0 5rem 0;
    &__list{
        display: grid;
        grid-template-columns: repeat(3,1fr);
        width: 90%;
        margin: auto;
        row-gap: 2rem;
        column-gap: 2rem;
    }
    &__title{
        width: 90%;
        margin: auto;
        font-weight: 400;
    }
    &__search{
        display: flex;
        flex-direction: row;
        align-items: center;
        column-gap: 3rem;
        width: 90%;
        margin: 0 auto 2rem;
    }
    &__result{
        width: 90%;
        margin: auto;
    }
}

#outlined-size-small,#demo-multiple-chip{
    background-color: white;
    border-radius: 5px;
}

@media (max-width: 767px){
    .projects{
        padding-top: 3rem;
        &__list{
        grid-template-columns: repeat(1,1fr);
        row-gap: 1rem;
        margin-top: 2rem;          
        }
        &__search{
            flex-direction: column;
            align-items: flex-start;
            row-gap: 1rem;
        }
        /*#outlined-size-small{
            width: 150px;
        }*/
        #formcontrol{
            width: 100%;
        }
        #textformcontrol{
            width: 100%;
        }
    }  
}

@media (min-width : 768px) and (max-width: 1023px){
    .projects{
        padding-top: 4rem;
        &__list{
        grid-template-columns: repeat(2,1fr);
        row-gap: 0;            
        }
    }
    #outlined-size-small,#demo-multiple-chip{
        width: 277px;
    }
}

@media (min-width: 1024px) and (max-width: 1439px){
    #outlined-size-small,#demo-multiple-chip{
        width: 277px;
    }
    .projects{
        padding-top: 7rem;
    }
}

@media (min-width: 1440px){
    #outlined-size-small,#demo-multiple-chip{
        width: 277px;
    }
    .projects{
        padding-top: 10rem;
    }
}
