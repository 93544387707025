.project{
    display: flex;
    flex-direction: column;
    place-self: center;
    width: 100%;
    height: 350px;
    column-gap: 1rem;
    &__mainImage{
        width: 100%;
        box-shadow: 0.05rem 0.1rem 0.3rem -0.03rem black;
        cursor: pointer;
        object-fit: cover;
    }
    &__list{
        display: flex;
        padding: 0;
        margin: 0;
        flex-wrap: wrap;
        &__element{
            display: flex;
            flex-direction: column;
            align-items: center;
            list-style-type: none;
        }
        &__icon{
            cursor: pointer;
            object-fit: contain;
        }
    }
    &__title{
        font-weight: 400;
        cursor: pointer;
    }
    &__link{
        cursor: pointer;
        text-decoration: none;
    }
}

@media (max-width: 424px){
    .project{
        height: auto;
        &__mainImage{
            height: 190px;
        }
        &__title{
            margin: 0;
        }
        &__description{
            margin: 0;
        }
        &__list{
            column-gap: .5rem;
            row-gap: .5rem;
            &__icon{
                width: 24px;
                height: 24px;
            }
            &__stack{
                margin: 0;
            }
        }
        &__informations{
            display: flex;
            flex-direction: column;
            row-gap: .5rem;
        }
    }
}

@media (min-width : 425px) and (max-width: 767px){
    .project{
        height: 300px;
        &__mainImage{
            height: 190px;
        }
        &__title{
            margin: 0;
        }
        &__description{
            margin: 0;
        }
        &__list{
            column-gap: .5rem;
            row-gap: .5rem;
            &__icon{
                width: 24px;
                height: 24px;
            }
            &__stack{
                margin: 0;
            }
        }
        &__informations{
            display: flex;
            justify-content: space-between;
            align-items: center;
        }
    } 
}

@media (min-width : 768px) and (max-width: 1023px){
    .project{
        height: 300px;
        &__mainImage{
            height: 190px;
        }
        &__title{
            margin: 0;
        }
        &__description{
            margin: 0;
        }
        &__list{
            column-gap: .5rem;
            row-gap: .5rem;
            &__icon{
                width: 18px;
                height: 18px;
            }
            &__stack{
                margin: 0;
            }
        }
        &__informations{
            display: flex;
            justify-content: space-between;
            align-items: center;
        }
    } 
}

@media (min-width: 1024px) and (max-width: 1439px){
    .project{
        &__mainImage{
            height: 220px;
        }
        &__title{
            margin: 0;
        }
        &__description{
            margin: 0;
        }
        &__list{
            column-gap: .5rem;
            row-gap: .5rem;
            &__icon{
                width: 24px;
                height: 24px;
            }
            &__stack{
                margin: 0;
            }
        }
        &__informations{
            display: flex;
            justify-content: space-between;
            align-items: center;
        }
    }   
}

@media (min-width: 1440px){
    .project{
        &__mainImage{
            height: 250px;
        }
        &__title{
            margin: 0;
        }
        &__description{
            margin: 0;
        }
        &__list{
            column-gap: .5rem;
            row-gap: .5rem;
            &__icon{
                width: 32px;
                height: 32px;
            }
            &__stack{
                margin: 0;
            }
        }
        &__informations{
            display: flex;
            justify-content: space-between;
            align-items: center;
        }
    }
}