.background{
    position: relative;
    display: flex;
    justify-content: center;
    z-index: 10;
    &__welcome{
        position: absolute;
        z-index: 2;
        top: 20%;
        &__text{
            color: white;
            text-align: center;
            font-weight: 100;
        }
        &__buttons{
            display: flex;
            flex-direction: row;
            justify-content: center;
            column-gap: 2rem;
            margin-top: 5rem;
        }
        &__button{
            border: 0;
            cursor: pointer;
            transition: .2s;
        }
    }
    &__image{
        width: 100%;
        z-index: 1;
    }

}

#projects_button{
    background-color: #61dafb;
}

#projects_button:hover{
    background-color: white;
}

#contact_button{
    background-color: #9e9e9e;
}

#contact_button:hover{
    background-color: white;
}

@media (max-width: 424px){
    .background{
        &__welcome{
            top: 10%;
            padding: 7px;
            width: 90%;
            &__buttons{
                margin-top: 2rem;
                flex-direction: column;
                row-gap: 1rem;
                align-items: center;
            }
            &__button{
                padding: 12px 0;
                width: 120px;
                font-size: 14px;
            }
        }
        &__image{
            filter: blur(4px);
            height: 500px;
            object-fit: cover;
        }
    }
    .welcome{
        font-size: 32px;
    }
    .project_contact{
        font-size: 24px;
    }    
}

@media (min-width : 425px) and (max-width: 767px){
    .background{
        &__image{
            filter: blur(4px);
            height: 500px;
            object-fit: cover;
        }
        &__welcome{
            width: 90%;
            &__button{
                padding: 12px 0;
                width: 120px;
                font-size: 14px;
            }
            &__buttons{
                margin-top: 3rem;
            }
        }
    }
    .welcome{
        font-size: 32px;
    }
    .project_contact{
        font-size: 24px;
    }
}

@media (min-width : 768px) and (max-width: 1023px){
    .background{
        &__image{
            filter: blur(4px);
        }
        &__welcome{
            &__button{
                padding: 12px 0;
                width: 120px;
                font-size: 14px;
            }
        }
    }
    .welcome{
        font-size: 32px;
    }
    .project_contact{
        font-size: 24px;
    }
}

@media (min-width: 1024px) and (max-width: 1439px){
    .background{
        &__image{
            filter: blur(8px);
        }
        &__welcome{
            &__button{
                padding: 12px 0;
                width: 120px;
                font-size: 14px;
            }
        }                 
    }
    .welcome{
        font-size: 38px;
    }
    .project_contact{
        font-size: 30px;
    } 
}

@media (min-width: 1440px){
    .background{
        &__image{
            filter: blur(10px);
        }
        &__welcome{
            &__button{
                padding: 16px 0;
                width: 140px;
                font-size: 17px;
            }
        }
    }
    .welcome{
        font-size: 48px;
    }
    .project_contact{
        font-size: 32px;
    }
}