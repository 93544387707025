.content-wrap{
    flex: 1;
}

.light{
    background: rgb(255,255,252);
    .header{
        &__nav_element:hover{
            text-decoration: underline black;
            &__sidemenu:hover{
                cursor: default;
                text-decoration: none;
            }
        }
        #back_home,&__logoutIcon,&__settings{
            filter: invert(0);
        }
        &__link{
            color:black
        }
    }
    .project{
        &__link{
            color:black;
        }
        &__mainImage{
            opacity: 1;
        }
    }
    .footer{
        &__link{
            color:black
        }
    }
    #prev,
    #next,
    #check,
    #uncheck,
    #responsive,
    #previous_project,
    #next_project,
    #error_icon,
    #information,
    #messages_back,
    #settings{
        filter: invert(0);
    }
    
    .projectPage{
        &__details{
            &__links{
                &__link{
                    color:black
                }
            }
        }
        &__otherLinks{
            &__link{
                color: black;
            }
        }
        &__navigation{
            &__button{
                &__title{
                    color: black;
                }
            }
        }
    }

    .about{
        &__link{
            color:black
        }
    }
}

.dark{
    background: #212121;
    color: white;
    .header{
        &__nav_element:hover{
            text-decoration: underline white;
            &__sidemenu:hover{
                cursor: default;
                text-decoration: none;
            }
        }
        &__link{
            color:white
        }
        &__logoutIcon,&__settings{
            filter: invert(1);
        }
    }
    .project{
        &__link{
            color:white;
        }
        &__mainImage{
            opacity: 0.8;
        }
    }
    .footer{
        &__link{
            color:white
        }
    }
    #prev,
    #next,
    #check,
    #uncheck,
    #responsive,
    #previous_project,
    #next_project,
    #error_icon,
    #information,
    #messages_back,
    #settings{
        filter: invert(1);
    }
    
    .projectPage{
        &__details{
            &__links{
                &__link{
                    color:white
                }
            }
        }
        &__otherLinks{
            &__link{
                color: white;
            }
        }
        &__navigation{
            &__button{
                &__title{
                    color: white;
                }
            }
        }
    }
    
    .about{
        &__link{
            color:white
        }
    }
}

.header_light{
    background-color: #e8e8e8;
    .header{
        &__nav_element:hover{
            text-decoration: underline black;
        }
        &__menu-btn{
            filter: invert(0);
        }
    }
    #back_home,#logout,#settings{
        filter: invert(0);
    }
}

.footer_light{
    background-color: #e8e8e8;
    .footer{
        &__link{
            color:black
        }
    }
}

.header_dark{
    background-color: black;
    .header{
        &__name,&__frontend,&__nav_element{
            color: white;
        }
        &__nav_element:hover{
            text-decoration: underline white;
        }
        &__menu-btn{
            filter: invert(1);
        }
    }
    #back_home,#logout,#settings{
        filter: invert(1);
    }
}

.footer_dark{
    background-color: black;
    .footer{
        &__link,&__element{
            color:white
        }
    }
}