.message{
    display: flex;
    flex-direction: column;
    &__bubble{
        padding: 0.7rem 0.8rem;
        border-radius: 22px;
        width: fit-content;
        margin: 0;
        white-space: pre-line;
    }
    &__timestamp{
        padding: 0 .3rem
    }
}