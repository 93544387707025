.reset{
    min-height: 150vh;
    &__wrapper{
        margin: auto;
    }
    &__form{
        display: flex;
        flex-direction: column;
        width: 370px;
        margin: auto;
        border-radius: 5px;
        padding: 2rem;
        row-gap: 1rem;
        &__resetPassword,&__newPassword{
            font-weight: 100;
            margin: 0;
        }
        &__newPassword{
            margin-bottom: 3rem;
        }
        &__input{
            background-color: white;
            border-radius: 5px;
        }
        &__bcrypt{
            margin: 0;
            color: grey;
            &__link{
                color: grey;
            }
        }
    }
    &__invalid{
        display: flex;
        flex-direction: column;
        width: 370px;
        margin: auto;
        padding:2rem;
        row-gap:1rem;
        &__message{
            font-weight: 100;
            margin: 0;
            text-align: center;
        }
    }
}

@media (max-width: 424px) {
    .reset {
        &__wrapper {
            width: 95%;
        }

        &__form, &__invalid {
            width: 100%;
            padding: 1rem 0;
        }
    }
}

@media (min-width : 425px) and (max-width: 767px) {
    .reset {
        &__wrapper {
            width: 90%;
        }

        &__form, &__invalid {
            width: 100%;
            padding: 1rem 0;
        }
    }
}

@media (min-width : 768px) and (max-width: 1023px) {
    .reset {
        &__wrapper {
            width: 90%;
        }
    }
}

@media (min-width: 1024px) and (max-width: 1439px) {
    .reset {
        &__wrapper {
            width: 80%;
        }
    }
}

@media (min-width: 1440px) {
    .reset {
        &__wrapper {
            width: 70%;
        }
    }
}