.contact{
    min-height: 150vh;
    &__wrapper{
        margin: auto;
        padding-top: 2rem;
    }
    &__content{
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    &__title,&__text{
        font-weight: 100;
    }
    &__buttons{
        display: flex;
        flex-direction: row;
        justify-content: center;
        column-gap: 2rem;
        margin-top: 5rem;
        &__button{
            border: 0;
            cursor: pointer;
            transition: .2s;
            padding: 14px 0;
            width: 140px;
            font-size: 15px;
            font-weight: 100;
        }
        &__button:hover{
            background-color: #e0e0e0;
        }
    }
    /*&__form{
        display: flex;
        flex-direction: column;
        row-gap: 2rem;
        width: 500px;
        margin: auto;
        &__text{
            font-weight: 100;
        }
        &__label-input{
            display: flex;
            flex-direction: column;
        }
        &__input{
            background-color: white;
            border-radius: 5px;
        }
        &__buttons{
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            margin-top: 3rem;
        }
    }*/
}

label{
    font-weight: 100;
    margin-bottom: .5rem;
}

.message-btn{
    background-color: #61dafb;
}

.conversation-btn{
    background-color: #9e9e9e;
}

@media (max-width: 424px) {
    .contact {
        &__wrapper {
            width: 90%;
        }
        /*&__form {
            width: 100%;
            padding: 1rem 0;
        }*/
        &__title{
            font-size: 24px;
        }
        &__text{
            font-size: 18px;
        }
        &__buttons{
            flex-direction: column;
            justify-content: center;
            row-gap: 1rem;
            &__button{
                font-size: 14px;
            }
        }
    }
}

@media (min-width : 425px) and (max-width: 767px) {
    .contact {
        &__wrapper {
            width: 90%;
        }
        &__title{
            font-size: 26px;
        }
        &__text{
            font-size: 20px;
        }
        /*&__form {
            width: 100%;
            padding: 1rem 0;
        }*/
    }
}

@media (min-width : 768px) and (max-width: 1023px) {
    .contact {
        &__wrapper {
            width: 90%;
        }
        &__title{
            font-size: 28px;
        }
        &__text{
            font-size: 22px;
        }
    }
}

@media (min-width: 1024px) and (max-width: 1439px) {
    .contact {
        &__wrapper {
            width: 80%;
        }
        &__title{
            font-size: 30px;
        }
        &__text{
            font-size: 24px;
        }
        &__buttons{
            justify-content: flex-start;
        }
    }
}

@media (min-width: 1440px) {
    .contact {
        &__wrapper {
            width: 70%;
        }
        &__title{
            font-size: 32px;
        }
        &__text{
            font-size: 26px;
        }
        &__buttons{
            justify-content: flex-start;
        }
    }
}