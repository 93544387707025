.simpleMessage{
    min-height: 150vh;
    &__wrapper{
        margin: auto;
        padding-top: 2rem;
    }
    &__message{
        &__name,&__email,&__date,&__message{
            font-weight: 100;
        }
        &__name{
            font-size: 32px;
        }
        &__email,&__date{
            font-size: 20px;
            margin: 0;
        }
        &__message{
            font-size: 18px;
        }
    }
}

@media (max-width: 424px) {
    .simpleMessage {
        &__wrapper {
            width: 95%;
        }
    }
}

@media (min-width : 425px) and (max-width: 767px) {
    .simpleMessage {
        &__wrapper {
            width: 90%;
        }
    }
}

@media (min-width : 768px) and (max-width: 1023px) {
    .simpleMessage {
        &__wrapper {
            width: 90%;
        }
    }
}

@media (min-width: 1024px) and (max-width: 1439px) {
    .simpleMessage {
        &__wrapper {
            width: 80%;
        }
    }
}

@media (min-width: 1440px) {
    .simpleMessage {
        &__wrapper {
            width: 70%;
        }
    }
}