.projectPage{
    min-height: 150vh;
    padding-bottom: 5rem;
    &__wrapper{
        width: 70%;
        margin: auto;
    }
    &__title{
        font-weight: 100;
        margin-bottom: 0;
        margin-top: 0;
    }
    &__description{
        font-weight: 100;
        margin: 0;
    }
    &__stack{
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        column-gap: 2rem;
        row-gap: 2rem;
        padding: 0;
        &__element{
            display: flex;
            flex-direction: column;
            align-items: center;
            list-style-type: none;
            width: min-content;
            &__icon{
                object-fit: contain;
            }
            &__name{
                font-size: 16px;
                font-weight: 100;
                margin: 0;
                text-align: center;
            }
        }
    }
    &__details{
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        column-gap: 2rem;
        &__links_paragraphers{
            display: flex;
            flex-direction: column;
        }
        &__links{
            display: flex;
            flex-direction: column;
            &__linkName{
                font-weight: 100;
                margin: 0;
            }
            &__link{
                font-weight: 100;
                word-break: break-word;
            }
        }
        &__paragraphers{
            display: flex;
            flex-direction: column;
            padding: 0;
            &__paragrapher{
                list-style-type: none;
                font-weight: 100;
            }
        }
    }
    &__informations_carrousel{
        display: flex;
        flex-direction: column;
    }
    &__carrousel{
        &__images{
            display: flex;
            flex-direction: column;
            row-gap: .5rem;
            &__image_count{
                display: flex;
                flex-direction: column;
                place-self: center;
                &__image{
                height: 300px;
                width: 500px;
                object-fit: contain;
                cursor: pointer;
                filter: brightness(1);
                transition: filter .3s ease-in-out;
                box-shadow: 0.05rem 0.1rem 0.3rem -0.03rem black;
                }
                &__image:hover{
                    filter: brightness(0.5);
                    transition:  filter .3s ease-in-out;
                }
                &__count{
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    column-gap: 1rem;
                    font-weight: 100;
                    text-align: center;
                    &__icon{
                        width: 32px;
                        height: 32px;
                        cursor: pointer;
                    }
                }
            }
        }
    }
    &__otherLinks{
        display: flex;
        flex-direction: column;
        padding: 0;
        row-gap: 1rem;
        &__linkName{
            list-style-type: none;
            font-weight: 100;
        }
        &__link{
            font-weight: 100;
            word-break: break-word;
        }
    }
    &__responsive{
        display: flex;
        flex-direction: row;
        align-items: center;
        column-gap: 7px;
        font-weight: 100;
    }
    &__navigation{
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        &__link{
            text-decoration: none;
        }
        &__button{
            display: flex;
            flex-direction: row;
                &__title{
                    text-transform: none;
                    font-size: 14px;
                }
                &__next{
                    transform: rotate(180deg);
                }
        }
    }
}

#responsive{
    width: 48px;
    height: 48px;
}

#check,#uncheck{
    width: 24px;
    height: 24px;
}

#next{
    transform: rotate(180deg);
}

@media (max-width: 424px){
    .projectPage{
        padding-top: 1rem;
        &__wrapper{
            width: 95%;
        }
        &__title{
            font-size: 24px;
        }
        &__description{
            font-size: 18px;
        }
        &__stack{
            &__element{
                &__icon{
                    width: 32px;
                    height: 32px;
                }
            }
        }
        &__informations_carrousel{
            row-gap: 1rem;
        }
        &__carrousel{
            &__images{
                display: flex;
                justify-content: center;
                &__image_count{
                     &__image{
                        width: 100%;
                    }
                }
            }
        }
        &__details{
            display: block;
        }
        &__navigation{
            //flex-direction: column;
            row-gap: 1rem;
            justify-content: space-between;
        }
    }
}

@media (min-width : 425px) and (max-width: 767px){
    .projectPage{
        padding-top: 2rem;
        &__wrapper{
            width: 90%;
        }
        &__title{
            font-size: 30px;
        }
        &__description{
            font-size: 20px;
        }
        &__stack{
            &__element{
                &__icon{
                    width: 48px;
                    height: 48px;
                }
            }
        }
        &__informations_carrousel{
            row-gap: 1rem;
        }
        &__carrousel{
            &__images{
                display: flex;
                justify-content: center;
                &__image_count{
                    &__image{
                    width: 100%;
                    }
                }
            }
        }
        &__details{
            display: block;
        }
        &__navigation{
            justify-content: space-between;
        }
    }
}

@media (min-width : 768px) and (max-width: 1023px){
    .projectPage{
        padding-top: 3rem;
        &__wrapper{
            width: 90%;
        }
        &__title{
            font-size: 36px;
        }
        &__description{
            font-size: 24px;
        }
        &__stack{
            &__element{
                &__icon{
                    width: 72px;
                    height: 72px;
                }
            }
        }
        &__informations_carrousel{
            row-gap: 2rem;
        }
        &__carrousel{
            width: auto;
        }
        &__details{
            display: block;
            &__images{
                &__image_count{
                    &__image{
                        width: 100%;
                    }
                }
            }
        }
    }
}

@media (min-width: 1024px) and (max-width: 1439px){
    .projectPage{
        padding-top: 4rem;
        &__wrapper{
            width: 80%;
        }
        &__title{
            font-size: 44px;
        }
        &__description{
            font-size: 28px;
        }
        &__stack{
            &__element{
                &__icon{
                    width: 96px;
                    height: 96px;
                }
            }
        }
        &__informations_carrousel{
            row-gap: 2rem;
        }
        &__carrousel{
            width: auto;
        }
        &__details{
            &__images{
                &__image{
                    width: 400px;
                }
            }
        }
    }
}

@media (min-width: 1440px){
    .projectPage{
        padding-top: 5rem;
        &__wrapper{
            width: 70%;
        }
        &__title{
            font-size: 48px;
        }
        &__description{
            font-size: 32px;
        }
        &__stack{
            &__element{
                &__icon{
                    width: 96px;
                    height: 96px;
                }
            }
        }
        &__informations_carrousel{
            row-gap: 2rem;
        }
        &__details{
            flex-direction: row;
        }
    }
}
